//
//
// https://tieto.nurminen.dev sivuston lähdekoodi
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//


@font-face {  
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;  
    src: url('FRONTEND/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('FRONTEND/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('FRONTEND/fonts/Montserrat-ExtraBold.ttf') format('truetype');
}
