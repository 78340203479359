//
//
// https://tieto.nurminen.dev sivuston lähdekoodi
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//


$textcolor: #000;
$effectcolor: #5f17e7;

@use "bulma/sass/utilities"  with (
  $family-primary: '"Alegreya", serif',
  $background: #fff,
  $primary: $effectcolor,
  $text: $textcolor,
  $link: $textcolor
);

@use "bulma/sass/base";
@use "bulma/sass/helpers/typography";
@use "bulma/sass/helpers/spacing";
@use "bulma/sass/helpers/visibility";
@use "bulma/sass/helpers/flexbox";
@use "bulma/sass/themes";
@use "bulma/sass/utilities/initial-variables" as iv;
@use "bulma/sass/utilities/mixins";

@use "bulma/sass/components/navbar";
@use "bulma/sass/components/modal";
@use "bulma/sass/components/dropdown";
@use "bulma/sass/elements/button";
@use "bulma/sass/elements/image";
@use "bulma/sass/elements/content";
@use "bulma/sass/layout/container";
@use "bulma/sass/layout/section";
@use "bulma/sass/layout/footer";
@use "bulma/sass/form/checkbox-radio";
@use "bulma/sass/form/input-textarea";
@use "bulma/sass/form/tools";

@import './font-faces.scss';



//
// Site-wide styling
//
html,
body {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.7rem;
  min-height: 100dvh;
}

body { padding: 0 10px; }

@media screen and (max-width: iv.$tablet - 1) {
  html,
  body {
    font-size: 17px;
  }
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.link, a,
a:link,
a:visited,
a:active,
a:focus-visible {
    text-decoration: underline;
    color: $effectcolor;
    transition: none !important;
    word-wrap: break-word;
}

a.button {
  text-decoration: none;
}

.link:hover, a:hover {
    background-color: #5f17e7;
    color: #fff;
}

.link { cursor: pointer; }


::selection {
  background: $effectcolor;
  color: #fff;
}


input {
  font-size: 0.9rem;
  font-family: "Montserrat", sans-serif;
}


input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 2rem;
  padding: 0.1rem;
  border: 2px solid #000;
  border-radius: 0.5rem;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.9rem;
  height: 0.9rem;
  clip-path: polygon(19% 45%, 43% 69%, 80% 7%, 94% 15%, 42% 99%, 3% 60%);
  transform: scale(0);
  background-color: #000;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:hover {
  color: #f6f6f6;
}


.dark-only { display: none; }
.light-only { display: block; }


ul {
  list-style-type: disc;
  margin-left: 40px;
  margin-bottom: 1rem;
}


.text-sm {
  font-size: 0.895rem;
}

p, ul, h2, h3 {
  -webkit-hyphens: auto;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-chars: 6 3 3;
  -webkit-hyphenate-limit-lines: 2;
  -webkit-hyphenate-limit-last: always;
  -webkit-hyphenate-limit-zone: 8%;
  -moz-hyphens: auto;
  -moz-hyphenate-limit-chars: 6 3 3;
  -moz-hyphenate-limit-lines: 2;
  -moz-hyphenate-limit-last: always;
  -moz-hyphenate-limit-zone: 8%;
  -ms-hyphens: auto;
  -ms-hyphenate-limit-chars: 6 3 3;
  -ms-hyphenate-limit-lines: 2;
  -ms-hyphenate-limit-last: always;
  -ms-hyphenate-limit-zone: 8%;
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  word-wrap: break-word;
}



//
//
// ██████╗  █████╗ ██████╗ ██╗  ██╗
// ██╔══██╗██╔══██╗██╔══██╗██║ ██╔╝
// ██║  ██║███████║██████╔╝█████╔╝
// ██║  ██║██╔══██║██╔══██╗██╔═██╗
// ██████╔╝██║  ██║██║  ██║██║  ██╗
// ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝
//
//
@mixin dark-theme {

  $darkbg: #181223; // #181223
  $darktext: #e7e6e6; // #e7e6e6

  html, body {
    background-color: $darkbg;
  }

  body {
    color: $darktext;
  }

  .link, a,
  a:link,
  a:visited,
  a:active,
  a:focus-visible {
      text-decoration: underline;
      color: $darktext;
      transition: none !important;
      word-wrap: break-word;
  }

  a.button {
    text-decoration: none;
  }

  strong { color: $darktext; }

  ::selection {
    background: $effectcolor;
    color: $darktext;
  }

  input[type="checkbox"] {
    border-color: #f6f6f6;
  }
  
  input[type="checkbox"]::before {
    background-color: #f6f6f6;
  }
  
  input[type="checkbox"]:hover {
    color: #000;
  }
  

  .dark-only { display: block; }
  .light-only { display: none; }

  header {
    .header-left {
      a.tieto-link,
      a.tieto-link:link,
      a.tieto-link:visited,
      a.tieto-link:active {
          color: $darktext;
      }
    }

    .header-buttons {
      .light-dark-mode-button {
        background-image: url('FRONTEND/images/light-mode.svg');
      }

      div.dropdown#login-menu {
        > .dropdown-trigger {
          background-image: url('FRONTEND/images/login-icon-darkmode.svg');
        }
    
        &.is-active > .dropdown-trigger {
            background-image: url('FRONTEND/images/login-icon-lightmode.svg');
            background-color: #f6f6f6;
        }
      }

      .changelog {
        border-color: #f6f6f6;
      }
    }

    div.dropdown#user-menu {
      > .dropdown-trigger {
        background-image: url('FRONTEND/images/account-icon-darkmode.svg');
      }
  
      &.is-active > .dropdown-trigger {
        background-image: url('FRONTEND/images/account-icon.svg');
        background-color: #f6f6f6;
      }
    }
  }

  .content {
    h1, h2, h3, h4, h5,
    table thead th {
      color: $darktext;
    }


    section.kurssi-entry {
      &:hover {
        box-shadow: 0px 0px 50px rgba(209, 209, 209, 0.2) !important;
      }
  
      a,
      a:link,
      a:visited,
      a:active,
      a:hover {
        color: $darktext;
        background: transparent;
      }
    }

    section.kurssi-entry-tulossa,
    section.kurssi-entry {
      box-shadow: 0px 0px 50px rgba(202, 202, 202, 0.102) !important;
      margin-bottom: 2rem;

      h2 {
        color: $darktext;
      }
    }

    section.kurssi-entry-tulossa {
      color: $darktext;

    }


    #kurssi-sisalto {
      .otsikko-container {
        .otsikko {
          &.iso {
            border-bottom: 5px solid $darktext;
          }
        }
      }

      .avaa-kurssi {
        box-shadow: 0px 0px 60px rgba(202, 202, 202, 0.102) !important;
      }

    }

    #salasana-sivu #salasana-step2 fieldset.number-code > div > input {
      border-bottom: 3px solid #f6f6f6;
      background-color: $darkbg;
      color: $darktext;
    }

    #salasana-sivu #salasana-step2 fieldset.number-code > div > input:focus {
      background-color: #2e333d;
    }
  }

  footer {
    background-color: $darkbg;

    .site-footer {
      .footer-center {
        .sahkoposti {
          background-image: url('FRONTEND/images/email-icon-light.svg');
        }
  
        .puhelin {
          background-image: url('FRONTEND/images/phone-icon-light.svg');
        }
      }
    }
  }


  .micromodal__container {
    background-color: $darkbg;
  
    > .modal__header {
      > button.modal__close {
        &:hover {
          background-color: $effectcolor;
        }
      }
    }
  }

}



//
//
// ██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗ 
// ██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
// ██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
// ██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝
//
//
header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 30px;

  z-index: 10;
  position: relative;

  .header-left {
    height: 56px;

    flex-grow: 1;

    a.tieto-link,
    a.tieto-link:link,
    a.tieto-link:visited,
    a.tieto-link:active {
      display: inline-block;
      color: $textcolor;
      text-decoration: none;

      h1 {
        font-size: 2.5rem;
        font-weight: 800;
        text-transform: none;
        font-style: italic;
        line-height: 2.5rem;

        .kauttakautta {
          font-size: 2.2rem;
          font-style: normal;
        }

      }

      &:hover {
        background-color: transparent;
        text-decoration: none;

        > h1 > span.text {
          text-decoration: underline;
        }
      }
    }
  }

  .header-buttons {
    height: 46px;
    display: flex;
    align-items: center;
    gap: 1rem;

    .light-dark-mode-button {
      height: 33px;
      width: 33px;

      cursor: pointer;

      background: url('FRONTEND/images/dark-mode.svg') 0px 0px no-repeat;
      background-size: 33px 33px;
    }

    div.dropdown {
      > .dropdown-trigger {
        display: flex;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;
        height: 46px;
        justify-content: center;
        width: 46px;
  
        &:hover {
          background-color: #f6f6f6;
        }
      }
    }


    div.dropdown#login-menu {
      > .dropdown-trigger {
        background: url('FRONTEND/images/login-icon-lightmode.svg') 3px 4px no-repeat;
        background-size: 39px 39px;
      }
  
      &.is-active {
        > .dropdown-trigger {
          background-image: url('FRONTEND/images/login-icon-lightmode-active.svg');
        }
      }

      > .dropdown-menu form#tieto-login-form {
        display: grid;
        grid-template-columns: 30px 200px;
        align-items: center;

        .kirjaudu-email, .kirjaudu-salasana {
          grid-column: 1 / 3;
          margin-bottom: 0.5rem;
          text-align: left;

          > label {
            text-transform: uppercase;
            font-weight: bold;
          }
        }

        .kirjaudu-salasana {
          margin-bottom: 0.2rem;
        }

        .unohditko-salasanasi {
          grid-column: 1 / 3;
          font-size: 0.7rem;
          margin-bottom: 0.5rem;
        }

        .muista-minut-disclaimer {
          font-size: 0.7rem;
          line-height: 1rem;
          text-align: left;
          margin-bottom: 1rem;
        }

        .kurssi-kirjaudu-nappi-container {
          grid-column: 1 / 3;
        }
      }
    }

    div.dropdown#user-menu {
      display: none;

      > .dropdown-trigger {
        background: url('FRONTEND/images/account-icon.svg') 3px 4px no-repeat;
        background-size: 39px 39px;
      }
  
      &.is-active {
        > .dropdown-trigger {
          background-image: url('FRONTEND/images/account-icon-active.svg');
        }
      }

      > .dropdown-menu {
        a, a:link, a:visited, a:active {
          text-decoration: none;
        }
      }
  
    }

    .changelog {
      font-weight: bold;
      font-size: 0.9rem;
      border-color: #000;
    }

  }

  @media screen and (max-width: iv.$tablet - 1) {
    .header-left {
      height: 46px;
    }

    .header-buttons {
      gap: 0.7rem;

      .light-dark-mode-button {
        height: 25px;
        width: 25px;

        background-size: 25px 25px;

        > img { height: 25px; }
      }

      div.dropdown {
        > .dropdown-trigger {
          width: 43px;
          height: 42px;
        }
      }

      div.dropdown#login-menu,
      div.dropdown#user-menu {
          > .dropdown-trigger {
          background-position:  center center;
          background-size: 37px 37px;
        }
      }

      .ajankohtaista-mobile {
        padding-left: 10px;
        padding-right: 10px;

        img { width: 20px; }
      }

      .changelog {
        &.mobile {
          padding: 5px;

          img { width: 25px; }
        }
      }
    
    }
  }


}



//
//
//  ██████╗ ██████╗ ███╗   ██╗████████╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔██╗ ██║   ██║   █████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚████║   ██║   ███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚══════╝╚═╝  ╚═══╝   ╚═╝
//
//
.content {
  h1, h2, h3, h4, h5,
  table thead th {
    color: $textcolor;
  }

  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ///
  /// ETUSIVU
  ///
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  .tieto-hero {
    font-size: 1.111rem;
  }

  section.kurssi-entry {
    &:hover {
      box-shadow: 0px 0px 30px rgba(62, 62, 62, 0.3) !important;
    }

    a,
    a:link,
    a:visited,
    a:active,
    a:hover {
      display: flex;
      align-items: flex-start;
      color: $textcolor;
      text-decoration: none;
      background: transparent;
      padding: 15px;
      gap: 1rem;
      justify-content: space-between;

      &.tili {
        align-items: center;
      }
    }

    @media screen and (max-width: iv.$tablet - 1) {
      a,
      a:link,
      a:visited,
      a:active,
      a:hover {
        flex-direction: column;

        .vasen-content {
          .kurssi-title {
            flex-direction: column;
            align-items: flex-start;
          }
        }
    

        .oikea-boksi:not(.tili) {
          display: none;
        }
      }
    }
  }


  section.kurssi-entry-tulossa,
  section.kurssi-entry {
    transition: 0.2s ease;
    border: 0;
    box-shadow: 0px 0px 30px rgba(62, 62, 62, 0.2) !important;
    margin-bottom: 2rem;

    .vasen-content {
      flex-grow: 1;

      h2 {
        margin: 0 0 10px 0;
      }

      .kurssi-hinta-tutustu,
      .kurssi-maksuton {
        background-color: #5f17e7;
        border-radius: 12px;
        padding: 5px 10px;
        color: #fff;
        font-weight: bold;
        font-size: 1.2rem;
        margin-left: 10px;
        opacity: 1;
      }

    }

    h2 {
      color: $textcolor;

      &.tili { margin-bottom: 0; }
    }

    .oikea-boksi {
      padding: 10px;
      width: 120px;
      height: 136px;
      min-width: 120px;
      min-height: 136px;

      &.tili {
        background-color: #5f17e7;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        color: #fff;
        height: 115px;
        width: 115px;
        justify-content: center;
        height: auto;
        min-height: auto;
        line-height: 1.4rem;
      }

      .oikea-teksti {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;

        &.tili {
          font-weight: normal;
          font-size: 1rem;
        }
      }

      .oikea-alateksti {
        text-align: center;
        font-size: 0.8rem;

        &.tili {
          font-weight: bold;
          font-size: 1rem;
        }
      }
    }

    @media screen and (max-width: iv.$tablet - 1) {
      .oikea-boksi {
        flex-direction: row !important;
        width: 100% !important;
        min-width: auto !important;
        height: auto !important;
        min-height: auto !important;
        gap: 1rem !important;

        &.tili { gap: 0.5rem; }
      }
    }
  }

  section.kurssi-entry-tulossa {
    display: flex;
    align-items: center;
    color: $textcolor;
    background: transparent;
    padding: 15px;
    gap: 1rem;
    justify-content: center;

    h2 {
      opacity: 0.5;
    }

    .tulossa-ilmoita {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      form {
        display: flex;
        gap: 5px;
        align-items: center;
      }
  
    }
  }


  .tekijasta-riku {
    display: flex;
    gap: 1rem;

    .rikukuva {
      width: 200px;
      min-width: 200px;
      height: 200px;
      min-height: 200px;

      img {
        filter: drop-shadow(2px 2px 4px rgba(0,0,0,.5));
  
        border: 3px solid #ff7c00;
    
        padding: 2px;
    
        border-radius: 9999px;
      }
  
    }
  }

  @media screen and (max-width: iv.$tablet - 1) {
    .tekijasta-riku {
      flex-wrap: wrap;
      justify-content: center;
    }
  }




  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ///
  /// KURSSI
  ///
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  #kurssi-sisalto {
    h1 {
      font-size: 2rem;
      line-height: 2rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 1rem;
      word-break: break-word;
    }
  
    h2 {
      line-height: 2.2rem;
    }
  
  
    .otsikko-container {
      display: flex;
      gap: 1rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
      align-items: center;
  
      .otsikko {
        flex-grow: 1;
  
        &.iso {
          padding-bottom: 7px;
    
          border-bottom: 5px solid black;
        }
    
        h2 {
          font-size: 2.2rem;
          line-height: 2.2rem;
          font-weight: 600;
          margin: 0;
        }
    
        h3 {
          font-size: 1.8rem;
          line-height: 1.8rem;
          font-weight: 400;
          margin: 0;
        }
      }
  
      .hakemisto-nuoli {
        font-size: 1.9rem;
        line-height: 1.9rem;
      }
    
    }
  
    @media screen and (max-width: iv.$tablet - 1) {
      .otsikko-container {
        gap: 0.5rem;
  
        .otsikko h2 { font-size: 2rem; }
        .otsikko h3 { font-size: 1.7rem; }
      }
    }
  
  
  
    h3 {
      font-size: 1.7rem;
      line-height: 1.5rem;
      font-weight: 400;
      margin-bottom: 1rem;
      margin-top: 0 !important;
      word-break: break-word;
    }
  
  
    .article-date {
      color: #999;
      margin-bottom: 1rem;
  
      a { color: #999; }
  
      a:hover { color: #000; }
    }
  
    ol.hakemisto {
      margin-bottom: 2.5rem;
  
      > li {
        font-size: 1.105rem;
  
        ol {
          list-style-type: none;
          margin: 5px 0 0 2rem;
  
          > li {
            font-size: 1.105rem;
          }
        }
      }
  
      li.toc-locked {
        > ol > li, > ol > li > ol > li, &::marker, > span { color: gray; }
      }
  
    }
  
    @media screen and (max-width: iv.$tablet - 1) {
      ol.hakemisto {
        margin-left: 1.5rem;
  
        > li > ol {
          margin-left: 0.5rem;
        }
      }
    }
  
    pre {
      padding: 0;
      line-height: 1.2rem;
    }
  
  
    .codefont {
      background-color: var(--bulma-pre-background);
      font-family: var(--bulma-code-family);
      font-size: 0.895rem;
      font-weight: var(--bulma-code-weight);
      padding: 5px;
    }
    
    
    .huomio-boksi {
      background-color: var(--bulma-pre-background);
      margin: 0 15px;
      padding: 20px;
      border-radius: 10px;
    
      h3 {
        background: url('../images/huomio-icon.png') top left no-repeat;
        background-size: 50px;
        padding-left: 60px;
        padding-top: 8px;
        height: 50px;
      }
    }
  
  
    p.fade-out {
      position: relative;
      -webkit-mask-image: linear-gradient(to bottom, black 20%, transparent 100%);
      mask-image: linear-gradient(to bottom, black 20%, transparent 100%);
    }
   
  
    .avaa-kurssi {
      margin: 20px;
      -webkit-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.75);
      border-radius: 10px;
      padding: 20px 10px;
      text-align: center;
  
      h2.avaa-kurssi-heading {
        text-transform: uppercase;
      }
  
      button.osta {
        color: #fff;
        padding: 20px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.5rem;
      }

      @media screen and (max-width: iv.$tablet - 1) {
        button.osta {
          font-size: 1.3rem;
        }
      }

      form#kurssi-rekisteroidy {
        label {
          text-transform: uppercase;
          font-weight: bold;
        }
      }

      .disclaimer {
        font-size: 0.7rem;
        line-height: 1rem;
        text-align: left;
        margin-bottom: 1rem;
      }

      #kirjaudu-avaa-kurssi {

        .kurssi-login-container {
          display: flex;
          justify-content: center;

          #kurssi-login-form {
            display: grid;
            grid-template-columns: 30px 230px;
            align-items: center;
  
            .kirjaudu-email, .kirjaudu-salasana {
              grid-column: 1 / 3;
              margin-bottom: 0.5rem;
              text-align: left;
  
              > label {
                text-transform: uppercase;
                font-weight: bold;
              }
            }
    
            .kurssi-kirjaudu-nappi-container {
              grid-column: 1 / 3;
            }
    
          }
  
        }

      }
  
    }

    #avaa-kurssi-virhe {
      display: none;
    }

  }



  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ///
  /// TILI
  ///
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  
  #tili-sivu {
    #postituslista {
      display: flex;
      align-items: flex-start;
      gap: 5px;
    }
  }
  
  #salasana-sivu {
    margin: 2rem 0;

    form label {
      font-weight: 700;
      text-transform: uppercase;
    }

    #salasana-step2 {
      display: none;

      // Password reset verification code input
      // https://codepen.io/RobertAron/pen/gOLLXLo
      fieldset.number-code {
        // overflow: auto;
        > div {
          > input:not(:last-child) {
            margin-right: 20px;
          }
        }

        margin-bottom: 15px;

        input.code-input {
          font-size: 1.286rem !important;
          width: 1.5em;
          text-align: center;
          flex: 1 0 1em;
          border-bottom: 3px solid #3b3b3b;
        }

        input.code-input.invalid {
          border-bottom: 3px solid hsl(348, 100%, 61%);
        }

        input{
          border: none;
          border-radius: 0 !important;

          &:invalid {
            box-shadow: none;
          }
          &:focus{
            outline: none;
            background: #EDF4F8;
          }
        }
      }
    }

    #salasana-step3 {
      display: none;

      .pysy-kirjautuneena {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 5px;

        label {
          text-transform: none;
          font-weight: normal;
        }
      }

      .tietosuojaseloste-link {
        font-size: 0.7rem;
      }
    }
  }


  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ///
  /// KIITOS OSTOKSESTASI
  ///
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  
  #kiitos-ostoksestasi-sivu {
    text-align: center;
  }
}



//
//
// ███████╗ ██████╗  ██████╗ ████████╗███████╗██████╗ 
// ██╔════╝██╔═══██╗██╔═══██╗╚══██╔══╝██╔════╝██╔══██╗
// █████╗  ██║   ██║██║   ██║   ██║   █████╗  ██████╔╝
// ██╔══╝  ██║   ██║██║   ██║   ██║   ██╔══╝  ██╔══██╗
// ██║     ╚██████╔╝╚██████╔╝   ██║   ███████╗██║  ██║
// ╚═╝      ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚═╝  ╚═╝
//
//
.footer {
  margin: 0 -10px;

  .site-footer {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  
    .footer-left,
    .footer-center,
    .footer-right {
      flex: 33.33%;
    }
  
    .footer-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .sahkoposti {
        background: url('FRONTEND/images/email-icon.svg') 0px 2px no-repeat;
        background-size: 25px 25px;
        padding-left: 33px;
        margin-bottom: 1rem;
      }

      .puhelin {
        background: url('FRONTEND/images/phone-icon.svg') 0px 0px no-repeat;
        background-size: 25px 25px;
        padding-left: 33px;
        margin-bottom: 1rem;
      }

      .instagram {
        background: url('FRONTEND/images/ig-logo.svg') 0px 2px no-repeat;
        background-size: 25px 25px;
        padding-left: 33px;
        margin-bottom: 1rem;
      }
    }
  
    .footer-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .link {
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: iv.$tablet - 1) {
  .footer {
    .site-footer {
      display: block;

      .footer-left,
      .footer-center,
      .footer-right {
        margin-bottom: 2rem;

        .link {
          text-align: left;
        }
      }

      .footer-left {
        text-align: center;
      }

      .footer-center > .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .footer-right {
        align-items: center;
      }
    }
  }
}



//
//
// ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗
// ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║
// ██╔████╔██║██║   ██║██║  ██║███████║██║
// ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║
// ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗
// ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝
//
//
.micromodal {
  display: none;
}

.micromodal.is-open {
  display: block;
}

.micromodal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.65);
  z-index: 30;
}

.micromodal__container {
  position: relative;
  overflow: hidden;
  max-width: 800px;
  min-width: 500px;
  height: 97dvh;
  margin: 0 10px;
  padding: 30px;
  background-color: #fff;
  border-radius: 4px;

  > .modal__header {
    height: 60px;
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 15px;

    > h2 {
      font-size: 1.75em;
      font-weight: 800;
    }

    @media screen and (max-width: iv.$tablet - 1) {
      > h2 {
        font-size: 1.5em;
        font-weight: 700;
      }
      }

    > button.modal__close {
      width: 40px;
      height: 40px;

      border: 0;
      outline: 0;

      border-radius: 7px;

      font-size: 1.5rem;

      &:hover {
        background-color: #f6f6f6;
      }
    }
    
    > .modal__close:before { content: "\2715"; }
  }

  > .modal__content {
    height: calc(97dvh - 200px);
    overflow-y: auto;
    padding-right: 5px;

    > h3 {
      font-size: 1.3em;
      font-weight: 700;
      margin-bottom: 0.3rem;
    }

    p {
      margin-bottom: 1rem;
    }

    a:focus-visible { outline: 0; }

  }

  > .modal__footer {
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    text-align: center;
  }
  
}

@media screen and (max-width: iv.$tablet - 1) {
  .micromodal__container {
    min-width: 97%;
  }
}

.micromodal[aria-hidden="false"] .micromodal__overlay {
  animation: microModalFadeIn .2s cubic-bezier(0.0, 0.0, 0.2, 1);
}

@keyframes microModalFadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}



//
//
// ███╗   ███╗██╗███████╗ ██████╗
// ████╗ ████║██║██╔════╝██╔════╝
// ██╔████╔██║██║███████╗██║
// ██║╚██╔╝██║██║╚════██║██║
// ██║ ╚═╝ ██║██║███████║╚██████╗
// ╚═╝     ╚═╝╚═╝╚══════╝ ╚═════╝
//
//
.loading-container {
  align-items: center;
  background: rgba(0, 0, 0, .3);
  bottom: 0;
  display: none;
  flex-wrap: nowrap;
  justify-content: center;
  left: 0;
  gap: 20px;
  position: fixed;
  right: 0;
  top: 0;
  flex-direction: column;
  z-index: 40;

  .loading-spinner {
    height: 100px;
    width: 100px;
    border: 10px solid #ccc;
    border-right-color: #673AB7;
    border-radius: 50%;
    animation: rotate 1.5s linear infinite;
  }
  
  .loading-text {
    color: #fff;
    opacity: 1;
    z-index: 20;
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    padding: 10px;
    border-radius: 10px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}



//
//
// ██████╗  █████╗ ██████╗ ██╗  ██╗
// ██╔══██╗██╔══██╗██╔══██╗██║ ██╔╝
// ██║  ██║███████║██████╔╝█████╔╝
// ██║  ██║██╔══██║██╔══██╗██╔═██╗
// ██████╔╝██║  ██║██║  ██║██║  ██╗
// ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝
//
//
[data-theme=dark] {
  @include dark-theme;
}